@font-face {
  font-family: 'Itim';
  src: url('/fonts/Itim-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pattaya';
  src: url('/fonts/Pattaya-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Krub';
  src: url('/fonts/Krub-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Charmonman';
  src: url('/fonts/Charmonman-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'K2D';
  src: url('/fonts/K2D-Regular.ttf') format('truetype');
}